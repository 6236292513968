.main_wrapper{
    padding: 100px 10rem;
}
.bill_receipt_wrap{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.bill_receipt_wrap div{
    width: 48%;
}
.receipt_address div{
    width: 48%;
}
.bill_receipt_wrap_img img{
    width: 20%;
}
.bill_receipt_wrap_text h1{
    font-size: 34px;
    font-weight: 600;
    color: #414141;
    text-transform: capitalize;
}
:focus{outline: none;}
.col_3{float: left;
    width: 48%;
    margin: 39px 0%;
    position: relative;} /* necessary to give position: relative to parent. */
.effect_1[type="text"]{font: 15px/24px "Lato", Arial, sans-serif; color: #333; width: 100%; box-sizing: border-box; letter-spacing: 1px;border-bottom: 1px solid #ccc;}

.effect_1, 
.effect_1{border: 0; padding: 7px 0; border-bottom: 1px solid #ccc;}

.effect_1 ~ .focus_border{position: absolute; bottom: 0; left: 0; width: 0; height: 2px; background-color: #F37021
    ; transition: 0.4s;}
.effect_1:focus ~ .focus_border{width: 100%; transition: 0.4s;}
.receipt_address{
    display: flex;
    justify-content: space-between;
    margin-top: 50px;
    align-items: center;
}
.continueRecipt_btn{
    background-color: #212F63;
    color: white;
    padding: 8px 30px;
    font-size: 16px;
    font-weight: 500;
    margin-top: 30px;
    border: none;

}
.receipt_address_location_items{
    display: flex;
    align-items: flex-start;
}
.receipt_address_location_items p{
    font-size: 16px;
    color: #414141;
    font-weight: 500;
    text-transform: capitalize;
    margin-left: 12px;
    width: 60%;
    margin-bottom: 20px;
}
.receipt_input_fields_wrap{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.receipt_shipped_address {
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
    width: 76%;
}
.receipt_shipped_address ul {
    list-style: none;
    padding-left: 0px;
    margin-top: 38px;
}
.receipt_shipped_address h3 {
    font-size: 20px;
    color: #414141;
    font-weight: 500;
    margin-top: 16px;
}
.shopping_details_boxes_lists li {
    color: #6F758B;
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
}
.shopping_details_boxes_lists{
    width: 48%;
}
.receipt_table_boxes{
    width: 100%;
}
.receipt_table_box{
    margin-top: 50px;
}
table {
    width: 100%;
    border-collapse: collapse;
  } 
  thead {
    font-size: 0.8rem;
    text-transform: uppercase;
    background: #f7f7f7;
  }
  table th{
    font-size: 14px;
    font-weight: 500;
    color: #b5b5b5;
    text-align: left;
  }
  tr {
    border-bottom: 1px solid #e6e6e6;
  }
  
  tr:hover {
    background-color: #f5f8fc;
  }
  
  td {
    font-weight: 100;
  }
  
  th, td {
    padding: 10px;
    font-size: 14px;
    font-weight: 400;
    color: #414141;

  }
  td:first-child,
  th:first-child {
    text-align: left;
  }
  td:nth-last-child(2),
  th:nth-last-child(2) {
    text-align: left;
  }
  td + td:not(:nth-last-child(2)) {
    text-align: left;
  }
  .table__button-group {
    visibility: hidden;
    transition: visibility 0.06s ease-in;
  }
  .table__button-group a {
    text-decoration: none;
    color: #0989ab;
    font-size: 0.8rem;
    font-weight: 500;
  }
  .table__button-group a:hover {
    color: #0989ab;
    opacity: 0.75;
  }
  .table__button-group a ~ a:before {
    content: "|";
    padding: 0 0.35rem;
  }
  tr:hover > td .table__button-group {
    visibility: visible;
  }
  .shopping_details_boxes_lists:nth-child(2) {
    width: 32%;
}
.shopping_details_boxes_lists {
    width: 32%;
    float: right;
    margin-right: 8rem;
}
.shopping_details_boxes_lists h3{
    font-size: 24px;
    color: #414141;
    font-weight: 600;
    margin-top: 16px;
    margin-bottom: 30px;
}
.shopping_details_prices_lists li {
    display: flex;
    justify-content: space-between;
}
.shopping_details_prices_lists p {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-top: 20px;
    border-top: 1px solid #e2e2e2;
    padding-top: 20px;
    color: #414141;
    font-size: 16px;
}
.sigbox p{
    color: #6F758B;
    font-size: 14px;
    font-weight: 400;
} 
  .sigbox input {
    width: 100%;
    border: none;
    border-bottom: 1px solid #D0D0D0;
    margin-bottom: 12px;
    padding-left: 0px;
  }
  .sigbox input:focus {
    outline: none;
  }
  /* .flex {
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
    text-align: center;
  } */
  .signature_wrap_box{
    display: flex;
    justify-content: space-between;
    margin-top: 25%;
    align-items: center;
  }
  .signature_wrap_box div{
    width: 40%;
  }
  @media only screen and (min-width: 1601px) and (max-width: 1800px){
    .main_wrapper{
        padding: 100px 7rem;
    }

    .bill_receipt_wrap div{
        width: 48%;
    }
    .bill_receipt_wrap_img img{
        width: 20%;
    }
    .receipt_shipped_address{
        width: 72%;
    }
  }
  @media only screen and (min-width: 1367px) and (max-width: 1600px){
    .main_wrapper{
        padding: 100px 5rem;
    }

    .bill_receipt_wrap div{
        width: 48%;
    }
    .bill_receipt_wrap_img img{
        width: 20%;
    }
    .receipt_shipped_address{
        width: 72%;
    }
    .receipt_address_location_items p{
        width: 70%;
    }
    .signature_wrap_box{
        margin-top: 30%;
    }
  }
  @media only screen and (min-width: 1201px) and (max-width: 1366px){
    .main_wrapper{
        padding: 100px 4rem;
    }

    .bill_receipt_wrap div{
        width: 48%;
    }
    .bill_receipt_wrap_img img{
        width: 20%;
    }
    .receipt_shipped_address{
        width: 72%;
    }
    .receipt_address_location_items p{
        width: 70%;
    }
    .signature_wrap_box{
        margin-top: 30%;
    }
  }
  @media only screen and (min-width: 1025px) and (max-width: 1200px){
    .main_wrapper{
        padding: 100px 4rem;
    }

    .bill_receipt_wrap div{
        width: 48%;
    }
    .bill_receipt_wrap_img img{
        width: 20%;
    }
    .receipt_shipped_address{
        width: 72%;
    }
    .receipt_address_location_items p{
        width: 90%;
    }
    .signature_wrap_box{
        margin-top: 30%;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 1024px){
    .main_wrapper{
        padding: 100px 2rem;
    }

    .bill_receipt_wrap div{
        width: 48%;
    }
    .bill_receipt_wrap_img img{
        width: 32%;
    }
    .receipt_shipped_address{
        width: 100%;
        margin-top: 20px;
    }
    .receipt_address_location_items p{
        width: 100%;
    }
    .signature_wrap_box{
        margin-top: 40%;
    }
  }
  @media only screen and (max-width: 767px){
    .main_wrapper{
        padding: 30px 1rem;
        margin-top: 5rem;
    }
/* 
    .bill_receipt_wrap div{
        width: 48%;
    } */
    .bill_receipt_wrap_text h1{
        font-size: 18px;
    }
    .bill_receipt_wrap{
        display: flex;
    }
    .bill_receipt_wrap_img img{
        width: 78%;
    }
    .receipt_shipped_address{
        width: 100%;
        margin-top: 20px;
    }
    .receipt_address_location_items p{
        width: 100%;
    }
    .signature_wrap_box{
        margin-top: 12%;
    }
    .receipt_address{
        display: block;
        margin-top: 20px;
    }
    .receipt_address div{
        width: 100%;
    }
    .receipt_input_fields_wrap{
        display: block;
    }
    .col_3{
        margin: 12px 0%;
    }
    .receipt_shipped_address{
        display: flex;
    }
    .shopping_details_boxes_lists{
        width: 60%;
        float: none;
        margin-right: 0px;
    }
    .shopping_details_boxes_lists:nth-child(2) {
        width: 48%;
    }
    .signature_wrap_box{
        display: block;
    }
    .signature_wrap_box div{
        width: 100%;
    }
    .receipt_shipped_address ul{
        margin-top: 0px;
    }
    .shopping_details_boxes_lists h3 {
        font-size: 16px;
        color: #414141;
        font-weight: 600;
        margin-top: 14px;
        margin-bottom: 8px;
    }
    .shopping_details_boxes_lists li{
        font-size: 14px;
    }
    table th{
        font-size: 10px;
    }
    th, td {
        padding: 5px;
        font-size: 10px;
        font-weight: 400;
        color: #414141;
    }
  }
  